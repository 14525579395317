import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { RenderBusinessComponent } from '../../solution-page/RenderBusinessComponent';
import { SectionSubTitle } from './SectionSubTitle';
import { SelectSwitcher } from '../switcher/SelectSwitcher';
import { ButtonSwitcher } from '../switcher/ButtonSwitcher';

const switchPositionList = [
  { id: '0', value: '', label: 'Type of business', placeholder: true },
  { id: '1', value: 'startup', label: 'Startup' },
  { id: '2', value: 'smallAndMedium', label: 'Small and medium business' },
  { id: '3', value: 'enterprises', label: 'Enterprises' },
];

const getTabTypeFromstring = (option: string) => {
  switch (option) {
    case 'enterprises':
      return 'enterprises';
    case 'smallAndMedium':
      return 'smallAndMedium';
    case 'startup':
      return 'startup';
    default:
      return 'startup';
  }
};
const labels = {
  left: {
    title: 'Startup',
    value: 'startup',
  },
  center: {
    title: 'Small and medium business',
    value: 'smallAndMedium',
  },
  right: {
    title: 'Enterprises',
    value: 'enterprises',
  }, 
};

export const GetMoreSection = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const typeofbusiness = queryParams.get('typeofbusiness');
  const [switchPosition, setSwitchPosition] = useState<string>(
    typeofbusiness !== null ? getTabTypeFromstring(typeofbusiness) : 'startup'
  );

  return (
    <div>
      <div className="lg:flex lg:justify-center">
        <SectionSubTitle className="text-center px-2 lg:px-0 lg:max-w-[49.5rem]">
          With cosmith.io you get more than just app
        </SectionSubTitle>
      </div>
      <div className="flex justify-center">
        <SelectSwitcher
          switchPosition={switchPosition}
          setSwitchPosition={setSwitchPosition}
          switchPositionList={switchPositionList}
        />
        <ButtonSwitcher
          switchPosition={switchPosition}
          setSwitchPosition={setSwitchPosition}
          labels={labels}
          searchWord="typeofbusiness"
        />
      </div>
      <div className="lg:min-h-[428px]">
        <RenderBusinessComponent switchPosition={switchPosition} />
      </div>
    </div>
  );
};
