import { Outlet } from 'react-router-dom';
import { SideBar, TopBar } from '../components/layout';
import { useState } from 'react';
import { useScreenSize } from '../hooks';
import { AccountProvider } from '../contexts/AccountContext';

export const AdminLayout = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const screen = useScreenSize();

  const isMobile = screen.lt.md;
  const isSideBarVisible = showSidebar || screen.gt.sm;

  return (
    <AccountProvider>
      <div className="flex flex-col md:flex-row h-screen">
        {isSideBarVisible ? (
          <SideBar isMobile={isMobile} setShowSidebar={setShowSidebar} />
        ) : null}
        <div
          className={[
            'flex flex-col flex-grow',
            !isMobile ? 'w-full' : '',
          ].join(' ')}
        >
          <TopBar
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
            isMobile={isMobile}
          />
          <div className={`${!isMobile ? 'ml-[11.625rem] xl:ml-[13.5rem]' : ''}`}>
            <Outlet />
          </div>
        </div>
      </div>
    </AccountProvider>
  );
};
